import request from '@/utils/request'

export function getSellableInventoryList(data, signal) {
  return request({
    url: `${process.env.VUE_APP_OMS_API}ofc-api-rest/inventory/select`,
    method: 'post',
    data,
    signal
  })
}

export function oneClickPush(data) {
  return request({
    url: `${process.env.VUE_APP_OMS_API}ofc-api-rest/inventory/oneKeyPush`,
    method: 'post',
    data
  })
}

export function getPlatformSiteShopByUserId(params) {
  return request({
    url: `${process.env.VUE_APP_OMS_API}ofc-api-rest/warehouse/getUserPlatformSiteShop`,
    method: 'get',
    params
  })
}

export function getStyleList(data) {
  return request({
    url: `${process.env.VUE_APP_OMS_API}ofc-api-rest/warehouse/getStyleInfo`,
    method: 'post',
    data
  })
}
