<template>
  <div>
    <el-form
      ref="formRef"
      :model="form"
      :rules="rules"
      inline
      label-width="140px"
      size="small"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="平台 / 站点 / 店铺" prop="platformSiteShop">
            <el-cascader
              v-model="form.platformSiteShop"
              style="width: 100%"
              :options="platformSiteShopOptions"
              placeholder="平台/站点/店铺"
              filterable
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="类目" prop="categoryId">
            <el-select
              v-model="form.categoryId"
              multiple
              clearable
              filterable
              collapse-tags
            >
              <el-option
                v-for="(item, index) in categoryOptions"
                :key="index"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="品牌" prop="brandName">
            <el-select
              v-model="form.brandName"
              multiple
              clearable
              filterable
              collapse-tags
            >
              <el-option
                v-for="(item, index) in brandOptions"
                :key="index"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="Style" prop="styleId">
            <el-select
              v-model="form.styleId"
              multiple
              clearable
              filterable
              collapse-tags
            >
              <el-option
                v-for="(item, index) in styleOptions"
                :key="index"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="SKU">
            <el-input
              v-model.trim="form.searchContentSingle"
              class="input-with-select"
              clearable
              placeholder="请输入"
              @focus="batchSearchVisible = false"
            >
              <template #append>
                <el-popover
                  v-model="batchSearchVisible"
                  placement="bottom"
                  trigger="manual"
                  width="300"
                >
                  <div style="text-align: right">
                    <div>
                      <el-input
                        v-model="form.searchContentMany"
                        type="textarea"
                        :rows="6"
                        placeholder="精确搜索，一行一项，最多支持200行"
                        @input="handleSkuInput"
                      />
                    </div>
                    <el-button
                      class="mt-2"
                      :loading="queryLoading"
                      @click="form.searchContentMany = ''"
                    >清空</el-button>
                    <el-button
                      class="mt-2"
                      :loading="queryLoading"
                      @click="batchSearchVisible = !batchSearchVisible"
                    >取消</el-button>
                    <el-button
                      class="mt-2"
                      type="primary"
                      :loading="queryLoading"
                      @click="handleQuery"
                    >查询</el-button>
                  </div>
                  <el-button
                    slot="reference"
                    icon="el-icon-more"
                    @click="handleOpenBatchSearch"
                  />
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label=" ">
            <el-button
              :loading="queryLoading"
              type="primary"
              @click="handleQuery"
            >
              {{ $t("page.search") }}
            </el-button>
            <el-button @click="handleReset">
              {{ $t("page.reset") }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <el-button
          type="primary"
          :loading="queryLoading"
          @click="handleExport"
        >导出</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <vxe-column :title="$t('page.No')" type="seq" width="80" />
      <vxe-column field="" title="平台 / 站点 / 店铺" width="220">
        <template #default="{ row }">
          <span>{{ row.plat }} / {{ row.site }} / {{ row.shop }}</span>
        </template>
      </vxe-column>
      <vxe-column field="sku" title="SKU" width="220" />
      <vxe-column field="categoryName" title="类目" width="220" />
      <vxe-column field="brandName" title="品牌" />
      <vxe-column field="styleName" title="Style" />
      <vxe-column field="colorName" title="Color" />
      <vxe-column field="sizeName" title="Size" />
      <!-- <vxe-column field="" title="FNSKU" />
      <vxe-column field="" title="SellerSKU" />
      <vxe-column field="" title="Asin" /> -->
      <vxe-column field="sellStock" width="120">
        <template #header>
          可售库存
          <el-popover placement="top-start" trigger="hover">
            每个店铺的可销售库存
            <span slot="reference" class="el-icon-question ml-1" />
          </el-popover>
        </template>
        <template #default="{ row }">
          <el-popover placement="top" trigger="hover" title="库存组成情况">
            <vxe-grid
              v-bind="inventoryCompositionOption"
              :data="row.shareShareList"
            />
            <template #reference>
              <span class="text-blue">{{ row.sellStock }}</span>
            </template>
          </el-popover>
        </template>
      </vxe-column>
    </vxe-table>
    <paging ref="pager" :pager="pager" end @update="handlePagerUpdate" />
  </div>
</template>

<script>
import Paging from '@/components/Paging/index.vue'
import { debounceGetTableMaxHeight } from '@/utils'
import { InventoryCompositionOption } from './const'
import {
  getPlatPagePage,
  getShareInventorySelect
} from '@/api/inventory-management'
import { getPlatformSiteShopByUserId } from '@/views/inventory-visualization/sellable-inventory/apis/list'
import { unifiedExport } from '@/api/a-base-host'
export default {
  name: 'StoreInventory',
  components: {
    Paging
  },
  data() {
    return {
      form: {
        sku: '',
        categoryId: [],
        brandName: [],
        styleId: [],
        platformSiteShop: ['AMAZON', 'GB', 'dreampairsEU'],
        // 关于sku搜索
        searchContentSingle: '',
        searchContentMany: ''
      },
      rules: {
        platformSiteShop: [
          {
            required: true,
            message: '请选择平台/站点/店铺',
            trigger: 'change'
          }
        ]
      },
      queryLoading: false,
      maxHeight: 0,
      tableData: [],
      currentRow: null,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      setSharingVisible: false,
      categoryOptions: [],
      brandOptions: [],
      styleOptions: [],
      platformSiteShopOptions: [],
      // 关于sku搜索
      maxLines: 200,
      batchSearchVisible: false
    }
  },
  computed: {
    inventoryCompositionOption() {
      console.log(InventoryCompositionOption)
      return InventoryCompositionOption
    },
    queryParams() {
      const {
        searchContentSingle,
        searchContentMany,
        platformSiteShop,
        ...rest
      } = this.form
      // sku
      const sku = this.batchSearchVisible
        ? this.preText(searchContentMany)
        : searchContentSingle
          ? [searchContentSingle]
          : []
      const [plat, site, shop] = platformSiteShop || []
      return {
        ...rest,
        plat,
        site,
        shop,
        sku: sku,
        current: this.pager.current,
        size: this.pager.size
      }
    }
  },
  watch: {},
  created() {
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  async mounted() {
    this.handleQuery()
    this.getBrand()
    this.getCategory()
    this.getStyle()
    this.getPlatformSiteShopOptions()
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    handleReset() {
      this.$refs.formRef.resetFields()
      this.form.platformSiteShop = ['AMAZON', 'GB', 'dreampairsEU']
      // 关于sku
      this.form.sku = []
      this.form.searchContentMany = ''
      this.form.searchContentSingle = ''
      this.handleQuery()
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    },
    handleQuery() {
      this.$refs.formRef.validate(async(valid) => {
        if (valid) {
          this.queryLoading = true
          if (this.controller) {
            this.controller.abort()
            setTimeout(() => {
              this.queryLoading = true
            })
          }
          this.controller = new AbortController()
          const signal = this.controller.signal

          if (this.queryChange) {
            this.pager.current = 1
          }

          const { datas } = await getPlatPagePage(
            this.queryParams,
            signal
          ).finally(() => {
            this.queryLoading = this.queryChange = false
          })

          this.tableData = datas?.records || []
          this.pager.total = datas?.pager.total || 0
        }
      })
    },
    handleSkuInput(value) {
      const text = value || ''
      const lines = this.preText(text)
      if (lines.length > this.maxLines) {
        this.form.searchContentMany = lines.slice(0, this.maxLines).join('\n')
        this.$message.warning(`最多输入${this.maxLines}行`)
      }
    },
    handleOpenBatchSearch() {
      this.batchSearchVisible = !this.batchSearchVisible
      // 如果打开了则将页码设置为1
      if (this.batchSearchVisible) {
        this.pager.current = 1
      }
    },
    async handleExport() {
      const params = {
        appName: 'oms',
        exportType: 'exportShareInventoryPlatPage',
        reqParam: JSON.stringify({
          ...this.queryParams
        })
      }
      this.queryLoading = true
      const { success } = await unifiedExport(params).finally(() => {
        this.queryLoading = false
      })

      if (success) {
        const downloadUrl = `${process.env['VUE_APP_OMS_API']}async-export/export-list`
        this.$message.success({
          dangerouslyUseHTMLString: true,
          message: `<div>导出成功，请前往<span style="color: #409eff;cursor: pointer" onclick="window.open('${downloadUrl}')">下载中心</span>查看</div>`
        })
      } else {
        this.$message.error('导出失败')
      }
    },
    async getBrand() {
      this.getShareInventorySelect({
        type: 3,
        key: 'brandOptions'
      })
    },
    async getCategory() {
      this.getShareInventorySelect({
        type: 2,
        key: 'categoryOptions'
      })
    },
    async getStyle() {
      this.getShareInventorySelect({
        type: 4,
        key: 'styleOptions'
      })
    },
    async getShareInventorySelect({ type, key, wareCode }) {
      try {
        // 下拉值选项 0履约仓库  1备货渠道   2 类目  3 品牌 4 Style
        const { datas } = await getShareInventorySelect({ type, wareCode })
        this[key] = datas || []
        return Promise.resolve(datas)
      } catch (error) {
        console.log(error)
      }
    },
    async getPlatformSiteShopOptions() {
      const { datas } = await getPlatformSiteShopByUserId()
      this.platformSiteShopOptions = datas || []
    },
    preText(pretext) {
      if (Array.isArray(pretext)) return pretext

      const array = (pretext || '')
        .replace(/\n/g, ',')
        .split(',')
        .filter((item) => item)
        .map((item) => item.replace(/\s/g, ''))

      return array
    }
  }
}
</script>
<style scoped>
.text-blue {
  color: #427cff;
  cursor: pointer;
}
</style>
