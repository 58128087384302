import request from '@/utils/request'

// 分页获取渠道库存
export function getShareInventoryPage(data, signal) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/shareInventory/page`,
    method: 'post',
    data,
    signal
  })
}

// 共享库存设置
export function setShareInventoryConfig(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/shareInventory/config`,
    method: 'post',
    data
  })
}

// 可售库存分配
export function setShareInventoryAssign(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/shareInventory/assign`,
    method: 'post',
    data
  })
}

// 可售库存分配列表查询
export function getShareInventoryAssignPage(data, signal) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/shareInventory/assignList`,
    method: 'get',
    data,
    signal
  })
}

// 下拉值选项 0履约仓库  1备货渠道   2 类目  3 品牌 4 Style
export function getShareInventorySelect({ type, wareCode }) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/shareInventory/selectList/${type}`,
    method: 'get',
    params: { wareCode }
  })
}

// 批量设置保底库存
export function setShareInventoryBatchConfig(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/shareInventory/batchConfig`,
    method: 'post',
    data
  })
}

// 分页获取渠道渠道库存
export function getPlatPagePage(data, signal) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/shareInventory/platPage`,
    method: 'post',
    data,
    signal
  })
}

// 导入共享配置预览
export function importShareConfigExcel(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/shareInventory/importShareConfigExcel`,
    method: 'post',
    data
  })
}

// 确认共享配置
export function importShareConfigExcelConfig(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/shareInventory/importShareConfigExcelConfig`,
    method: 'post',
    data
  })
}

// 导入库存分配信息预览
export function importPlatConfigExcel(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/shareInventory/importPlatConfigExcel`,
    method: 'post',
    data
  })
}

// 确认库存分配信息配置
export function importPlatConfigExcelConfig(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/shareInventory/importPlatConfigExcelConfig`,
    method: 'post',
    data
  })
}

