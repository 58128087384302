// 可售库存
export const InventoryCompositionOption = {
  border: true,
  resizable: true,
  showOverflow: true,
  columns: [
    { field: 'wareCodeName', title: '仓库', width: 120, align: 'center' },
    { field: 'channelName', title: '备货渠道', width: 120, align: 'center' },
    { field: 'stock', title: '可售库存', width: 120, align: 'center' }
  ]
}
